/* eslint-disable no-lone-blocks */

export function ruleDecisionColor(
  activities,
  ruleId,
  activityCompleted,
  executionValidations,
  executionStatus
) {
  let color = '#F9F9F9';
  const approvedStatus = ['APPROVED', 'WARNING'];
  activities?.forEach((activity) => {
    if (
      activity.status === 'completed' &&
      activity.type === 'action' &&
      activity.metadata?.action === 'ValidateRulesAction' &&
      activity.output
    ) {
      activity.output.forEach((output) => {
        if (output.ruleId === ruleId && activityCompleted) {
          const validationStatus = executionValidations.find(
            (validation) => validation.ruleId === ruleId
          );
          if (
            approvedStatus.includes(output.status) ||
            approvedStatus.includes(validationStatus?.status)
          ) {
            color = '#D1FFBD';
          } else if (output.status === 'REPROVED') {
            color = '#f9e4e4';
          }
        }
        if (
          output.ruleId === ruleId &&
          output.status === 'PENDING' &&
          executionStatus === 'PENDING'
        ) {
          color = '#ffecce';
        }
      });
    }
  });

  return color;
}
