/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState } from 'react';
import { Drag } from '@combateafraude/icons/general';
import {
  CicleApproved as ApprovedIcon,
  CicleError as ReprovedIcon,
  CicleImportantInfo as WarningIcon,
  CicleWarn as PendingIcon,
} from '@combateafraude/icons/others';
import { Col, Form, Input, Row, Select, Tooltip } from 'antd';
import { useDrag } from 'react-dnd';
import { ruleDecisionColor } from '../utils/ruleDecisionColor';

import './styles.less';

const { Option } = Select;
const Rule = ({
  name,
  type,
  removeBox,
  service,
  label,
  typeSource,
  ruleType,
  ruleId,
  serviceLabel,
  statusConfig,
  defaultStatus,
  activityCompleted,
  executionValidations,
  executionStatus,
  activities,
}) => {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: {
        name,
        service,
        label,
        typeSource,
        ruleType,
        ruleId,
        serviceLabel,
        statusConfig,
        defaultStatus,
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [name, type]
  );

  const color = ruleDecisionColor(
    activities,
    ruleId,
    activityCompleted,
    executionValidations,
    executionStatus
  );

  return (
    <div id="instepbox">
      <div
        ref={drag}
        style={{ opacity, backgroundColor: color }}
        className="in-step-box"
        data-testid="box"
      >
        <Form layout="vertical">
          <div className="mrg-vertical-10">
            <Row className="no-mrg no-pdd">
              <Col span={2} className="no-mrg no-pdd flex center pdd-right-10">
                <Drag height={20} width={20} />
              </Col>
              <Col span={22} className="no-mrg no-pdd">
                <Row className="no-mrg no-pdd flex">
                  <Col span={21} className="no-mrg no-pdd">
                    <Form.Item label="Rule" className="no-pdd no-mrg label-input">
                      <Input defaultValue={name} disabled></Input>
                    </Form.Item>
                  </Col>
                  <Col span={3} className="flex center-end">
                    <div
                      className="flex center-end"
                      onClick={removeBox}
                      style={{ marginBottom: 7, cursor: 'pointer' }}
                    ></div>
                  </Col>
                </Row>
                <Row className="no-mrg-horizon no-pdd flex pdd-top-10">
                  <Col span={11} className="no-mrg no-pdd">
                    <Form.Item label="NO MATCH" className="no-pdd no-mrg">
                      <StatusSelect
                        defaultValue={
                          statusConfig?.noMatch || defaultStatus?.NO_MATCH || 'REPROVED'
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11} className="no-mrg no-pdd">
                    <Form.Item label="MATCH" className="no-pdd no-mrg">
                      <StatusSelect
                        defaultValue={
                          statusConfig?.match || defaultStatus?.MATCH || 'APPROVED'
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
};

const StatusSelect = (props) => {
  const { handleChange, defaultValue } = props;
  const [isOpen, setOpenSelect] = useState(false);

  return (
    <Select
      className="width-100"
      onClick={() => setOpenSelect((v) => !v)}
      onChange={handleChange}
      onBlur={() => setOpenSelect(false)}
      open={isOpen}
      defaultValue={defaultValue}
      disabled
    >
      <Option value="APPROVED">
        <Tooltip title="With the rule approved, the step will have the final status approved.">
          <div className="select__option_container">
            <ApprovedIcon />
            <span className="approved-option">Approved</span>
          </div>
        </Tooltip>
      </Option>
      <Option value="REPROVED">
        <Tooltip title="With the rule rejected, the step will have the final status rejected.">
          <div className="select__option_container">
            <ReprovedIcon />
            <span className="reproved-option">Reproved</span>
          </div>
        </Tooltip>
      </Option>
      <Option value="WARNING">
        <Tooltip title="The warning status when activated will issue a notification about the respective rule and will continue validating the next steps.">
          <div className="select__option_container">
            <WarningIcon style={{ color: '#763ad7' }} />
            <span className="warning-option">Warning</span>
          </div>
        </Tooltip>
      </Option>
      <Option value="PENDING">
        <Tooltip title="With the rule pending, the flow will be interrupted in the respective step, until a manual action acts on the rule.">
          <div className="select__option_container">
            <PendingIcon />
            <span className="pending-option">Pending</span>
          </div>
        </Tooltip>
      </Option>
    </Select>
  );
};

export default Rule;
