const suppliers = [
  {
    supplier: 'Iproov',
    products: [
      { key: 'LA', value: 'LA' },
      { key: 'GPA', value: 'GPA' },
    ],
  },
  {
    supplier: 'FaceTec',
    products: [
      { key: '2D', value: 'Liveness2D' },
      { key: '3D', value: 'Liveness3D' },
    ],
  },
];

export default suppliers;
