import React, {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useParams } from 'react-router-dom';
import { Col, Form, Switch, message, Row, Select } from 'antd';
import { ArrowDown } from '@combateafraude/icons/general';
import { useFetch } from 'services/hooks';

import PropTypes from 'prop-types';
import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import './styles.less';
import suppliers from './suppliers';

const { Option } = Select;

const AntiFraudSettings = forwardRef(({ data, refreshListRef, closeModal }, ref) => {
  const { tenantId } = useParams();
  const [form] = Form.useForm();

  const { put: changeSettings } = useFetch();
  const { post: postMobile } = useFetch();
  const [initialValues, setInitialValues] = useState({});
  const [selectedSupplier, setSelectedSupplier] = useState(
    data?.liveness?.supplier?.name || 'Iproov'
  );

  // Obtaining supplier options
  const supplierOptions = suppliers?.map((opt) => ({
    key: opt.supplier,
    value: opt.supplier,
  }));

  console.log('🚀 ~ supplierOptions ~ supplierOptions:', supplierOptions);
  // Obtaining product options based on the selected supplier
  const productOptions =
    suppliers?.find((opt) => opt.supplier === selectedSupplier)?.products || [];

  const [productsPermissions, setProductsPermissions] = useState(data.products || []);

  const isCreate = !data.clientId;
  const [loadingSettings, setLoadingSettings] = useState(false);

  const resetValues = useCallback(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  const internalClose = useCallback(() => {
    resetValues();
    closeModal();
  }, [resetValues, closeModal]);

  useImperativeHandle(ref, () => ({
    resetValues,
  }));

  useEffect(() => {
    if (data.products) {
      setProductsPermissions(data.products);
    }
  }, [data.products, productsPermissions]);

  useEffect(() => {
    const initialFormValues = {
      textDetection: data.liveness?.integrations?.includes('TextDetection'),
      fingerprint: data.liveness?.integrations?.includes('Fingerprint'),
      deepFake: data.liveness?.integrations?.includes('DeepFake'),
      supplier: data.liveness?.supplier?.name || 'Iproov',
      product: data.liveness?.supplier?.product || 'LA',
    };
    setInitialValues(initialFormValues);
    form.setFieldsValue(initialFormValues);
  }, [data, form]);

  const defineInitialValues = useCallback(
    (refreshData) => {
      const initialFormValues = {
        textDetection: refreshData.liveness?.integrations?.includes('TextDetection'),
        fingerprint: refreshData.liveness?.integrations?.includes('Fingerprint'),
        deepFake: refreshData.liveness?.integrations?.includes('DeepFake'),
        supplier: refreshData.liveness?.supplier?.name || 'Iproov',
        product: refreshData.liveness?.supplier?.product || 'LA',
      };
      setInitialValues(initialFormValues);
      form.setFieldsValue(initialFormValues);
    },
    [form]
  );

  const updateSettings = useCallback(
    async (payload) => {
      if (!tenantId) {
        if (isCreate) {
          message.error('Houve um problema ao criar a chave.');
        } else {
          message.error('Houve um problema ao alterar a chave.');
        }
        return;
      }
      setLoadingSettings(true);

      const integrations = [];
      if (payload.textDetection) {
        integrations.push('TextDetection');
      }
      if (payload.fingerprint) {
        integrations.push('Fingerprint');
      }
      if (payload.deepFake) {
        integrations.push('DeepFake');
      }
      try {
        const body = {
          ...data,
          liveness: {
            imageExpireTime: payload.imageExpireTime,
            supplier: {
              name: payload.supplier,
              product: payload.product,
            },
            integrations: [...integrations],
          },
        };
        if (data.clientId) {
          const response = await changeSettings({
            url: `/clients/${tenantId}/mobile-tokens`,
            payload: body,
          });
          defineInitialValues(response.client);
        } else {
          await postMobile({
            url: `/clients/${tenantId}/mobile-tokens`,
            payload: body,
          });
          refreshListRef.current();
        }

        closeModal();
        setLoadingSettings(false);

        if (isCreate) {
          message.success('Nova chave gerada com sucesso.');
        } else {
          message.success('Chave atualizada com sucesso.');
        }
      } catch (err) {
        if (isCreate) {
          message.error('Houve um problema ao criar a chave.');
        } else {
          message.error('Houve um problema ao alterar a chave.');
        }
      }
    },
    [
      tenantId,
      data,
      changeSettings,
      postMobile,
      closeModal,
      refreshListRef,
      defineInitialValues,
      isCreate,
    ]
  );

  return (
    <div id="anti-fraud-settings-modal-component" className="no-mrg no-pdd">
      <PageTitle
        title="Configurações da chave mobile"
        subtitle="Altere os dados da chave mobile."
      />

      <Form
        form={form}
        layout="vertical"
        className="mrg-top-30"
        onFinish={updateSettings}
      >
        <Row gutter={16}>
          <Col span={12}>
            <div className="container-form-item-switch">
              <Form.Item
                valuePropName="checked"
                name="textDetection"
                initialValue={data?.liveness?.integrations?.includes('TextDetection')}
                style={{ margin: 0 }}
              >
                <Switch />
              </Form.Item>
              <span className="mrg-left-10 mrg-top-5 text-dark">Detecção de texto</span>
            </div>
          </Col>
          <Col span={12}>
            <div className="container-form-item-switch">
              <Form.Item
                valuePropName="checked"
                name="fingerprint"
                initialValue={data?.liveness?.integrations?.includes('Fingerprint')}
                style={{ margin: 0 }}
              >
                <Switch />
              </Form.Item>
              <span className="mrg-left-10 mrg-top-5 text-dark">Fingerprint</span>
            </div>
          </Col>
        </Row>
        <Row gutter={16} className="custom-padding-top">
          <Col span={12}>
            <div className="container-form-item-switch">
              <Form.Item
                valuePropName="checked"
                name="deepFake"
                initialValue={data?.liveness?.integrations?.includes('DeepFake')}
                style={{ margin: 0 }}
              >
                <Switch />
              </Form.Item>
              <span className="mrg-left-10 mrg-top-5 text-dark">
                Detecção de deepfake
              </span>
            </div>
          </Col>
        </Row>
        <Row gutter={16} className="custom-padding-top">
          <Col span={24}>
            <Form.Item
              name="supplier"
              label="Altere o fornecedor"
              className="text-dark"
              initialValue={selectedSupplier}
            >
              <Select
                className="text-dark mrg-to-5"
                suffixIcon={<ArrowDown />}
                placeholder="Selecione o fornecedor..."
                onChange={(value) => {
                  form.setFieldsValue({ product: value === 'Iproov' ? 'LA' : '2D' });
                  setSelectedSupplier(value);
                }}
              >
                {supplierOptions.map((supp) => (
                  <Option key={supp.key} value={supp.value}>
                    {supp.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} className="custom-padding-top">
          <Col span={24}>
            <Form.Item
              name="product"
              label="Selecione a solução"
              className="text-dark"
              initialValue={
                data?.liveness?.supplier?.product ||
                (selectedSupplier === 'Iproov' ? 'LA' : '2D')
              }
            >
              <Select
                className="text-dark mrg-to-5"
                suffixIcon={<ArrowDown />}
                placeholder="Selecione a solução..."
              >
                {productOptions.map((product) => (
                  <Option key={product.key} value={product.value}>
                    {product.key}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div className="gx-flex-row gx-justify-content-between mrg-top-10">
          <Button type="default" onClick={internalClose} className="btn-cancel">
            Cancelar
          </Button>
          <Button
            className="btn-custom btn-custom-primary"
            htmlType="submit"
            disabled={loadingSettings}
            loading={loadingSettings}
          >
            {loadingSettings ? 'Salvando...' : 'Salvar'}
          </Button>
        </div>
      </Form>
    </div>
  );
});

AntiFraudSettings.propTypes = {
  data: PropTypes.shape({
    clientId: PropTypes.string,
    clientSecret: PropTypes.string,
    createdAt: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.string),
    liveness: PropTypes.shape({
      integrations: PropTypes.arrayOf(PropTypes.string),
      supplier: PropTypes.shape({
        name: PropTypes.string,
        supplierType: PropTypes.string,
        product: PropTypes.string,
      }),
    }),
  }),
  refreshListRef: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

AntiFraudSettings.defaultProps = {
  data: {
    clientId: '',
    clientSecret: '',
    createdAt: '',
    products: [],
    liveness: {
      integrations: [],
      supplier: {
        name: '',
        supplierType: '',
        product: '',
      },
    },
  },
};

export default AntiFraudSettings;
