import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Typography } from 'antd';

import { useCustomer } from 'hooks/customer';

import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import { useComponentList } from 'components/List';

import androidLogo from 'assets/images/android-logo.png';
import appleLogo from 'assets/images/apple-logo.png';

import CardMobile from './components/CardMobile';

import useMobileCreate from '../MobileCreate';
import Wrapper from '../../wrapper';

import './styles.less';

const { Title, Link } = Typography;

const Mobile = () => {
  const refreshListRef = useRef(() => {});
  const { tenantId } = useParams();
  const { customer } = useCustomer();
  const { componentList, refreshList } = useComponentList({
    component: CardMobile,
    rowKey: 'clientSecret',
    getParams: {
      url: `/clients/${tenantId}/mobile-tokens`,
      config: {
        params: {},
      },
    },
  });
  const { openModal, MobileCreateModal } = useMobileCreate({ refreshListRef });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  return (
    <Wrapper>
      <div id="mobile-list-component">
        {MobileCreateModal}
        <Row align="middle" className="no-mrg">
          <Col flex={1} className="page-title">
            <PageTitle
              title="Chaves de acesso"
              subtitle="Selecione o tipo de consulta que você quer utilizar em seu app mobile."
            />
          </Col>
          <Col className="flex end-center documentation mrg-right-20">
            <Title level={4} className="no-mrg">
              Documentação:
            </Title>
            <Link href="https://docs.caf.io/sdks/android/caffaceliveness" target="_blank">
              <img src={androidLogo} alt="Logo do android" />
            </Link>
            <Link href="https://docs.caf.io/sdks/ios/getting-started" target="_blank">
              <img src={appleLogo} alt="Logo da apple" />
            </Link>
          </Col>
          <Col>
            <Button
              className="btn-custom-secondary"
              onClick={openModal}
              disabled={!customer}
            >
              Nova chave
            </Button>
          </Col>
        </Row>

        {customer && componentList}
      </div>
    </Wrapper>
  );
};

export default Mobile;
