import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ConfigProvider } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ptBR from 'antd/es/locale/pt_BR';
import moment from 'moment';
import 'moment/locale/pt-br';

import 'assets/vendors/style';
import 'styles/wieldy.less';
import 'assets/css/caf-icons.css';

import AppProvider from 'hooks';
import HttpInterceptor from 'utils/httpInterceptor';
import validateMessages from 'utils/formValidateMessages';

import Routes from './routes';

moment.updateLocale('pt-br', {
  weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
});

const App = () => (
  <BrowserRouter>
    <QueryParamProvider ReactRouterRoute={Route}>
      <DndProvider backend={HTML5Backend}>
        <AppProvider>
          <ConfigProvider form={{ validateMessages }} locale={ptBR}>
            <Routes />
          </ConfigProvider>
          <HttpInterceptor />
        </AppProvider>
      </DndProvider>
    </QueryParamProvider>
  </BrowserRouter>
);

export default App;
