/* eslint-disable import/no-cycle */
/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */

import React from 'react';
import { Handle } from 'reactflow';

import './styles.less';

import { nodeSize as ruleNodeSize } from './RulesNode';

export const nodeSize = {
  width: ruleNodeSize.width,
  height: ruleNodeSize.height,
};

const ButtonNode = (props) => {
  return (
    <>
      <Handle
        type="target"
        position="top"
        style={{ background: '#555', top: 0, width: 0, height: 0, opacity: 0 }}
        isConnectable={false}
      />
      <Handle
        type="source"
        position="bottom"
        id="a"
        style={{ top: 33, background: '#555', width: 0, height: 0, opacity: 0 }}
        isConnectable={false}
      />
    </>
  );
};

export default ButtonNode;
