import React from 'react';
import { Less, Plus, Centralize } from '@combateafraude/icons/general';
import { Tooltip } from 'antd';
import { ControlButton, Controls, useReactFlow } from 'reactflow';

import './styles.less';

const CustomControl = () => {
  const { zoomIn, zoomOut, fitView } = useReactFlow();

  return (
    <Controls
      style={{ bottom: 70, fill: '#ffffff !important' }}
      showFitView={false}
      showInteractive={false}
      showZoom={false}
      className="flow-control"
      position="bottom-right"
    >
      <Tooltip title="Center" placement="left">
        <ControlButton onClick={fitView} className="control-buttom zoom-in">
          <Centralize />
        </ControlButton>
      </Tooltip>
      <Tooltip title="Zoom" placement="left">
        <ControlButton onClick={zoomIn} className="control-buttom zoom-in">
          <Plus />
        </ControlButton>
        <ControlButton onClick={zoomOut} className="control-buttom zoom-out">
          <Less />
        </ControlButton>
      </Tooltip>
    </Controls>
  );
};

export default CustomControl;
