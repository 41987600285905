/* eslint-disable spaced-comment */
/* eslint-disable no-shadow */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-cycle */
/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
/* eslint-disable no-undef */

import * as d3 from 'd3-flextree';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MarkerType } from 'reactflow';

import { generateId } from '../utils/generateId';

const nodeSize = {
  width: 700,
  height: 650,
};

const WorkflowStepsContext = createContext({});

export const WorkflowStepsProvider = ({ children }) => {
  const [haveChanges, setHaveChanges] = useState(false);
  const [tree, _setTree] = useState();

  const setTree = useCallback((hierarchy) => {
    const layout = d3.flextree();

    // Aplica o layout à hierarquia
    layout(hierarchy);

    _setTree(hierarchy);
  }, []);

  useEffect(() => {
    const initialNodeId = generateId();

    const initialNode = {
      id: `InitialState-${initialNodeId}`,
      name: `InitialState-${initialNodeId}`,
      type: 'operation',
      size: [nodeSize.width, nodeSize.height],
      nodeMetadata: {
        label: 'Initial Step',
        accept: ['rules'],
        droppedItems: [],
        services: [],
        stepFunction: 'initial',
      },
      edgeMetadata: {
        style: { stroke: '#004AF7' },
        type: 'smoothstep',
        data: {
          type: 'switch',
        },
      },
      children: [],
    };

    const layout = d3.flextree();

    // Função para criar uma hierarquia D3 a partir dos dados
    const hierarchy = layout.hierarchy(initialNode);

    setTree(hierarchy);
  }, [setTree]);

  const nodes = useMemo(
    () =>
      tree?.descendants()?.map((node) => ({
        id: node.data.name,
        data: { ...node.data.nodeMetadata },
        position: { x: node.x, y: node.y },
        type: node.data.type,
      })) ?? [],
    [tree]
  );

  const edges = useMemo(
    () =>
      tree?.links()?.map((link) => ({
        ...link.source?.data?.edgeMetadata,
        id: `${link.source?.data?.name}-${link.target?.data?.name}`,
        source: link.source?.data?.name,
        target: link.target?.data?.name,
        type: 'smoothstep',
        ...(link.target?.data?.name?.includes('ApprovedState')
          ? {
              label: 'Approved',
              style: { stroke: '#39C560' },
              animated: true,
              labelStyle: { fill: '#ffffff', fontWeight: 700 },
              labelBgPadding: [8, 4],
              labelBgBorderRadius: 4,
              labelBgStyle: { fill: '#39C560', color: '#ffffff' },
              data: {
                type: 'approved',
              },
              markerEnd: {
                type: MarkerType.ArrowClosed,
                color: '#39C560',
              },
            }
          : {}),
        ...(link.target?.data?.name?.includes('ReprovedState')
          ? {
              label: 'Reproved',
              style: { stroke: '#E21B45' },
              animated: true,
              labelStyle: { fill: '#ffffff', fontWeight: 700 },
              labelBgPadding: [8, 4],
              labelBgBorderRadius: 4,
              labelBgStyle: { fill: '#E21B45', color: '#ffffff' },
              data: {
                type: 'reproved',
              },
              markerEnd: {
                type: MarkerType.ArrowClosed,
                color: '#E21B45',
              },
            }
          : {}),
        ...(link.target?.data?.name?.includes('__approved')
          ? {
              animated: true,
              style: { stroke: '#39c560' },
              label: 'Approved',
              labelStyle: { fill: '#ffffff', fontWeight: 700 },
              labelBgPadding: [8, 4],
              labelBgBorderRadius: 4,
              labelBgStyle: { fill: '#39C560', color: '#ffffff' },
              data: {
                type: 'approved_button',
              },
            }
          : {}),
        ...(link.target?.data?.name?.includes('__reproved')
          ? {
              animated: true,
              style: { stroke: '#e21b45' },
              label: 'Reproved',
              labelStyle: { fill: '#ffffff', fontWeight: 700 },
              labelBgPadding: [8, 4],
              labelBgBorderRadius: 4,
              labelBgStyle: { fill: '#E21B45', color: '#ffffff' },
              data: {
                type: 'reproved_button',
              },
            }
          : {}),
      })) ?? [],
    [tree]
  );

  return (
    <WorkflowStepsContext.Provider
      value={{
        haveChanges,
        setTree,
        setHaveChanges,
        nodes,
        edges,
        tree,
      }}
    >
      {children}
    </WorkflowStepsContext.Provider>
  );
};

export default function useWorkflowSteps() {
  return useContext(WorkflowStepsContext);
}
