/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
/* eslint-disable no-undef */
import * as d3 from 'd3-flextree';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactFlow, { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import PageTitle from 'components/PageTitle';

import { Row, Col, Spin } from 'antd';
import api from 'services/api';

import ButtonNode from './components/NodeTypes/ButtonNode';
import DecisionNode from './components/NodeTypes/DecisionNode';
import RulesNode from './components/NodeTypes/RulesNode';
import CustomControl from './components/CustomControl';
import useWorkflowSteps, { WorkflowStepsProvider } from './hooks/useWorkflowSteps';
import { isActivityCompleted } from './utils/activityCompleted';

import './styles.less';

const nodeTypes = {
  switch: DecisionNode,
  operation: RulesNode,
  button: ButtonNode,
};

const nodeSize = {
  width: 700,
  height: 650,
};

const decisionNodeSize = {
  width: 100,
  height: 200,
};

const WorkflowDetails = ({ transactionData }) => {
  const { tenantId, executionId } = useParams();

  const [workflowId, setWorkflowId] = useState(transactionData?.workflow?.instance);
  const [loadingNodes, setLoadingNodes] = useState(!!workflowId);

  const [services, setServices] = useState(null);

  const [hasWorkflow, setHasWorkflow] = useState();

  const { setTree, nodes, edges } = useWorkflowSteps();

  const getWorkflow = useCallback(
    async (allRules) => {
      setLoadingNodes(true);
      try {
        const response = await api.get(
          `/executions-services-requested/users/${tenantId}/executions/${executionId}?workflowInstance=${workflowId}`
        );

        const states =
          response?.data?.workflowInstance?.workflowDefinitionSnapshot?.states ?? [];

        const activities = response?.data?.workflowInstance?.activities ?? [];

        const isFinalState = (transition) => transition === 'Finalization State';

        const findByName = (name) => states.find((state) => state.name === name);

        // Esta função `buildTree` recebe um estado como entrada e cria uma estrutura de árvore com base nesse estado.
        const buildTree = (state) => {
          const element = {};

          const requestServices = state?.actions?.find(
            (st) => st?.name === 'RequestServicesAction'
          );
          const validateRules = state?.actions?.find(
            (st) => st?.name === 'ValidateRulesAction'
          );

          const rules = validateRules?.functionRef?.arguments?.payload?.rules?.map(
            (rule) => {
              const ruleData = allRules?.find(
                (relatedRule) => rule?._id === relatedRule?._id
              );
              return {
                name: ruleData?.name,
                service: ruleData?.service,
                label: ruleData?.name,
                typeSource: ruleData?.productType,
                serviceLabel: ruleData?.serviceLabel,
                ruleType: ruleData?.type,
                ruleId: ruleData?._id,
                statusConfig: rule?.statusConfig,
                country: ruleData?.country,
              };
            }
          );

          // Define o tamanho do nó com base no número de regras associadas.
          element.size = [
            nodeSize.width,
            rules?.length > 2
              ? nodeSize.height + (rules?.length - 2) * 200
              : nodeSize.height,
          ];
          element.id = state.id;
          element.name = state.name;
          element.type = state.type;

          element.nodeMetadata = {
            label: state?.name?.includes('ApprovedState')
              ? 'Approved Step'
              : state?.name?.includes('InitialState')
              ? 'Initial Step'
              : 'Reproved Step',
            accept: ['rules'],
            droppedItems: rules,
            stepFunction: state?.name?.includes('ApprovedState')
              ? 'approved'
              : state?.name?.includes('InitialState')
              ? 'initial'
              : 'reproved',
            services: requestServices?.functionRef?.arguments?.payload?.services?.map(
              (serv) => serv?.name
            ),
            removeAddStep: state?.transition === 'Finalization State' ? false : true,
            ActivityCompleted: isActivityCompleted(activities, state?.id),
            activities,
            executionValidations: transactionData?.validations,
            executionStatus: transactionData?.status,
          };
          element.edgeMetadata = {
            style: { stroke: '#004AF7' },
            type: 'smoothstep',
            data: {
              type: 'switch',
            },
          };

          if (state?.transition?.nextState) {
            state.transition = state.transition.nextState;
          }

          if (state?.transition?.includes('StateSwitch')) {
            const switchState = findByName(state.transition);

            if (switchState) {
              const switchElement = {};

              switchElement.size = [decisionNodeSize.width, decisionNodeSize.height];
              switchElement.id = switchState.id;
              switchElement.name = switchState.name;
              switchElement.type = switchState.type;
              switchElement.children = [];

              switchElement.nodeMetadata = {
                label: switchState?.metadata['ui:stepLabel'],
              };

              // Verifica se o estado padrão da condição não é um estado final.
              if (!isFinalState(switchState.defaultCondition.transition)) {
                const transitionState = findByName(
                  switchState.defaultCondition.transition
                );

                if (transitionState) {
                  // Adiciona o nó filho construído recursivamente à lista de filhos.
                  switchElement.children.push(buildTree(transitionState));
                }
              }

              // Verifica se o estado da primeira condição de dados não é um estado final.
              if (!isFinalState(switchState.dataConditions[0].transition)) {
                const transitionState = findByName(
                  switchState.dataConditions[0].transition
                );

                if (transitionState) {
                  // Adiciona o nó filho construído recursivamente à lista de filhos.
                  switchElement.children.push(buildTree(transitionState));
                }
              } else {
                // Se for um estado final, adiciona um botão ao nó filho.
                switchElement.children.push({
                  size: [nodeSize.width, nodeSize.height],
                  id: switchElement.id + '__approved',
                  name: switchElement.id + '__approved',
                  type: 'button',
                  nodeMetadata: {
                    type: 'approved_button',
                  },
                });
              }

              // Define os filhos do elemento atual como o nó de decisão
              element.children = [switchElement];
            }
          }

          // Retorna o objeto que representa o estado atual na árvore.
          return element;
        };

        // Chama a função `buildTree` com o primeiro estado do array "states" e cria a árvore.
        const filteredStates = states.filter((state) => state.type !== 'inject');
        const tree = buildTree(filteredStates[0]);

        setHasWorkflow(response);

        const layout = d3.flextree();

        // Função para criar uma hierarquia D3 a partir dos dados
        const hierarchy = layout.hierarchy(tree);

        setTree(hierarchy);
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingNodes(false);
      }
    },
    [api, workflowId, setTree]
  );

  const getServices = useCallback(async () => {
    try {
      const res = await api.get('/services', {
        params: {
          populateRules: true,
          global: true,
        },
      });

      const servicesData = res?.data?.response?.data;

      setServices(servicesData?.filter((doc) => doc.relatedRules?.length > 0));

      if (workflowId && !hasWorkflow) {
        let array = [];
        await servicesData?.forEach((sv) => {
          array = [
            ...array,
            ...sv?.relatedRules.map((rule) => {
              return {
                ...rule,
                service: sv?.source,
                productType: 'unknown',
                serviceLabel: sv?.title,
                country: sv?.operatingCountries,
              };
            }),
          ];
        });

        await getWorkflow(array);
      }
    } catch (e) {
      console.log(e);
    }
  }, [api, getWorkflow, hasWorkflow, workflowId]);

  useEffect(() => {
    if (!services) {
      getServices();
    }
  }, [getServices, services]);

  return (
    <div
      className="wrapper"
      style={{
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
        border: '1px solid #e8e8e8',
        maxHeight: '1500px',
      }}
    >
      {loadingNodes ? (
        <Spin className="flex center mrg-top-30" />
      ) : (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          nodesDraggable={false}
          fitView
          minZoom={0.4}
        >
          <CustomControl />
        </ReactFlow>
      )}
    </div>
  );
};

const Builder = ({ transactionData }) => {
  return (
    <ReactFlowProvider>
      <WorkflowStepsProvider>
        <Row align="middle">
          <Col span={16}>
            <PageTitle title="Regras executadas" />
          </Col>
          <Col span={8} className="formatted-switch-col"></Col>
        </Row>
        <WorkflowDetails transactionData={transactionData} />
      </WorkflowStepsProvider>
    </ReactFlowProvider>
  );
};
export default Builder;
