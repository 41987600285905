/* eslint-disable no-lone-blocks */

export function isActivityCompleted(activities, state) {
  let completed = false;
  activities?.forEach((activity) => {
    if (
      activity.metadata?.state === state &&
      activity.status === 'completed' &&
      activity.type === 'state'
    ) {
      completed = true;
    }
  });

  return completed;
}
