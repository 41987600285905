/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
import React from 'react';
import { Flow } from '@combateafraude/icons/builder';
import { Handle } from 'reactflow';
import './styles.less';

export const nodeSize = {
  width: 100,
  height: 200,
};

const DecisionNode = () => {
  return (
    <>
      <Handle
        type="target"
        position="top"
        style={{ background: '#555', top: 0, width: 0, height: 0, opacity: 0 }}
        isConnectable={false}
      />
      <div className="decision-node flex center">
        <Flow width={20} height={20} style={{ transform: 'rotate(315deg)' }}></Flow>
      </div>
      <Handle
        type="source"
        position="bottom"
        id="a"
        style={{ top: 33, background: '#555', width: 0, height: 0, opacity: 0 }}
        isConnectable={false}
      />
    </>
  );
};

export default DecisionNode;
